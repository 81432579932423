.footer {
  align-items: center;
  background-color: $color-dark1;
  display: flex;
  justify-content: center;
  min-height: 50vh;
  padding: 5rem 2rem 5rem;
  position: relative;
  @media (min-width: 60em) {
    padding: 6rem 8rem 9rem;
  }
}

.footer .angle {
  height: 4vw;
  left: 0;
  min-height: 30px;
  max-height: 60px;
  position: absolute;
  width: 100%;
  z-index: 5;
}

.footer .angle--bot {
  bottom: -0.5px;
  fill: $color-accent;
}

.footer-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50rem;
}

.footer-profile {
  display: block;
  flex-basis: 100%;
  margin-bottom: 3rem;
  justify-content: center;
  @media (min-width: 30em) {
    display: flex;
  }
}

.footer-profile-picture-wrap {
  background-color: $color-dark2;
  margin: 0 0 2rem;
  overflow: hidden;
  padding: 4px;
  position: relative;
  @media (min-width: 30em) {
    flex: 0 0 7rem;
    margin: 0 2rem auto 0;
  }
}

.footer-profile-lqip-wrap {
  bottom: 4px;
  left: 4px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 4px;
  top: 4px;
}

.footer-profile-lqip {
  filter: blur(6px);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(1.025);
  transition: transform $duration-transition * 2;
  width: 100%;
  .loaded & {
    transform: scale(1);
  }
}

.footer-profile-picture {
  display: block;
  overflow: hidden;
  position: relative;
}

.footer-profile-image {
  display: block;
  height: auto;
  position: relative;
  width: 100%;
  .has-js & {
    opacity: 0;
    transition: opacity $duration-transition * 2;
  }
  .has-js .loaded & {
    opacity: 1;
  }
}

.footer-profile-content {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0;
  max-width: 50rem;
}

.footer-profile-content a {
  color: #fff;
  font-weight: 700;
  position: relative;
  transition: color $duration-transition;
  white-space: nowrap;
  @mixin hover {
    color: $color-accent;
    transition-duration: 0ms;
  }
  .no-js &:focus-visible {
    @include hover;
  }
  &:focus-visible {
    color: $color-difference;
  }
  @media (hover: hover) {
    &:hover {
      @include hover;
    }
  }
  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.footer-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;
  margin-bottom: 3rem;
  @media (min-width: 40em) {
    gap: 2rem;
  }
}

.footer-link {
  align-items: center;
  background-color: $color-dark2;
  display: flex;
  padding: 0.75rem;
  position: relative;
  text-decoration: none;
  transition: background-color $duration-transition;
  width: calc(50% - 2px);
  @mixin hover {
    background-color: $color-dark3;
    position: relative;
    transition-duration: 0ms;
    z-index: 1;
  }
  .no-js &:focus-visible {
    @include hover;
  }
  &:focus-visible {
    background-color: $color-dark1;
  }
  @media (hover: hover) {
    &:hover {
      @include hover;
    }
  }
  @media (min-width: 40em) {
    background-color: transparent;
    padding: 0.25rem;
    width: auto;
    &:hover,
    .no-js &:focus-visible {
      background-color: transparent;
    }
    &:focus-visible {
      background-color: transparent;
    }
  }
}

.footer-link-icon {
  fill: #fff;
  flex-shrink: 0;
  margin: 0 0.5rem 0 0;
  transition: fill $duration-transition;
  width: 1.5rem;
  @mixin hover {
    fill: $color-accent;
    transition-duration: 0ms;
  }
  .no-js .footer-link:focus-visible & {
    @include hover;
  }
  .footer-link:focus-visible & {
    fill: $color-difference;
  }
  @media (hover: hover) {
    .footer-link:hover & {
      @include hover;
    }
  }
  @media (min-width: 40em) {
    display: inline-block;
    margin: 0 0.5rem 0 0;
    width: 1.75rem;
  }
  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.footer-link-text {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  transition: color $duration-transition;
  vertical-align: middle;
  @mixin hover {
    color: $color-accent;
    transition-duration: 0ms;
  }
  .no-js .footer-link:focus-visible & {
    @include hover;
  }
  .footer-link:focus-visible & {
    color: $color-difference;
  }
  @media (hover: hover) {
    .footer-link:hover & {
      @include hover;
    }
  }
  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.footer-meta {
  flex-basis: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
}

.footer-meta-divider {
  display: none;
  margin: 0 0.75rem;
  @media (min-width: 40em) {
    display: inline-block;
  }
}

.footer-meta-copyright {
  margin: 0;
  @media (min-width: 40em) {
    display: inline-block;
  }
}

.footer-meta-resume {
  margin-bottom: 0;
  margin-top: 1.25rem;
  @media (min-width: 40em) {
    display: inline-block;
    margin-top: 0;
  }
}

.footer-meta-resume-link {
  color: #fff;
  display: inline-block;
  font-weight: 700;
  position: relative;
  transition: color $duration-transition;
  white-space: nowrap;
  @mixin hover {
    color: $color-accent;
    transition-duration: 0ms;
  }
  .no-js &:focus-visible {
    @include hover;
  }
  &:focus-visible {
    color: $color-difference;
  }
  @media (hover: hover) {
    &:hover {
      @include hover;
    }
  }
  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.footer-meta-code {
  margin-bottom: 0;
  margin-top: 1.25rem;
  @media (min-width: 40em) {
    display: inline-block;
    margin-top: 0;
  }
}

.footer-meta-code-link {
  background-color: #fff;
  color: $color-dark1;
  display: inline-block;
  font-family: $font-monospace;
  margin: 0 0.1rem;
  padding: 0.25rem 0.35rem;
  position: relative;
  text-decoration: none;
  transition: background-color $duration-transition, color $duration-transition;
  @mixin hover {
    background-color: $color-accent;
    color: $color-dark1;
    transition-duration: 0ms;
  }
  .no-js &:focus-visible {
    @include hover;
  }
  &:focus-visible {
    background-color: $color-difference;
  }
  @media (hover: hover) {
    &:hover {
      @include hover;
    }
  }
  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.footer-meta-code-link span {
  background: currentColor;
  animation: caret-fade 1000ms infinite;
  display: inline-block;
  margin-left: 2px;
  width: 2px;
  @media (prefers-reduced-motion) {
    display: none;
  }
}
