.call-to-action {
  align-items: center;
  background-color: $color-dark2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40vh;
  padding: 5rem 2rem;
  position: relative;
  text-align: center;
  @media (min-width: 60em) {
    min-height: 50vh;
    padding: 7.5vw 10vw;
  }
}

.call-to-action .angle {
  height: 4vw;
  left: 0;
  min-height: 30px;
  max-height: 60px;
  position: absolute;
  width: 100%;
  z-index: 5;
}

.call-to-action .angle--top {
  fill: $color-dark1;
  top: -0.5px;
}

.call-to-action .angle--bot {
  bottom: -0.5px;
  fill: $color-dark1;
}

.call-to-action-title {
  color: $color-accent;
  font-family: $font-serif;
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 4rem;
  position: relative;
  &:after {
    background-color: $color-dark3;
    bottom: -2rem;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  @media (min-width: 30em) {
    font-size: 2.75rem;
  }
}

.call-to-action-subtitle {
  font-size: 1.25rem;
  margin-bottom: 0;
  position: relative;
  @media (min-width: 30em) {
    font-size: 1.5rem;
  }
}

.call-to-action-content {
  font-size: 1.25rem;
  @media (min-width: 30em) {
    font-size: 1.5rem;
  }
}

.call-to-action-content a {
  color: #fff;
  display: inline-block;
  font-weight: 700;
  transition: color $duration-transition;
  @mixin hover {
    color: $color-accent;
    transition-duration: 0ms;
  }
  .no-js &:focus-visible {
    @include hover;
  }
  &:focus-visible {
    color: $color-difference;
  }
  @media (hover: hover) {
    &:hover {
      @include hover;
    }
  }
  @media (prefers-reduced-motion) {
    transition: none;
  }
}
