@keyframes fade {
  0% {
    opacity: 0;
  }
  30%,
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(45deg) scale(0);
  }
  100% {
    transform: rotate(225deg) scale(1);
  }
}

@keyframes caret-fade {
  0%,
  10% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 0;
  }
}
