.project-grid {
  display: grid;
  gap: 2rem;
  margin-top: 2rem;
  padding-bottom: 1px; // needed for sticky titles to fully cover subpixels
  @media (min-width: 40em) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 80em) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cell {
  display: flex;
  flex-direction: column;
}

.cell-picture-wrap {
  background-color: $color-dark2;
  margin-bottom: 4px;
  overflow: hidden;
  padding: 4px;
  position: relative;
}

.cell-lqip-wrap {
  bottom: 4px;
  left: 4px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 4px;
  top: 4px;
}

.cell-lqip {
  filter: blur(6px);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(1.025);
  transition: transform $duration-transition * 2;
  width: 100%;
  .loaded & {
    transform: scale(1);
  }
}

.cell-picture {
  display: block;
  overflow: hidden;
  position: relative;
}

.cell-image {
  display: block;
  height: auto;
  position: relative;
  width: 100%;
  .has-js & {
    opacity: 0;
    transition: opacity $duration-transition * 2,
      filter $duration-transition * 2;
  }
  .has-js .loaded & {
    opacity: 1;
  }
}

.cell-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cell-content {
  background-color: $color-dark2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
}

.cell-title {
  color: #fff;
  font-family: $font-serif;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  line-height: 1.3;
  text-wrap: balance;
}

.cell-subtitle {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
  position: relative;
  text-wrap: balance;
}

.cell-subtitle:after {
  background-color: $color-dark3;
  content: "";
  display: block;
  height: 2px;
  margin-top: 1.25rem;
  width: 25%;
}

.cell-description {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.cell-location {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 1rem;
}

.cell-location a {
  color: inherit;
  position: relative;
  transition: color $duration-transition;
  &:hover,
  .no-js &:focus-visible {
    color: $color-accent;
    transition-duration: 0ms;
  }
  &:focus-visible {
    color: $color-difference;
  }
  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.cell-links {
  margin-top: auto;
}

.cell-link {
  background-color: $color-dark2;
  box-shadow: inset 4px 0 0 $color-dark3;
  color: #fff;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 4px;
  padding: 1rem;
  text-decoration: none;
  transition: background-color $duration-transition,
    border-color $duration-transition, box-shadow $duration-transition,
    color $duration-transition;
  &:hover,
  .no-js &:focus-visible {
    background-color: $color-dark3;
    box-shadow: inset 4px 0 0 $color-accent;
    color: #fff;
    transition-duration: 0ms;
  }
  &:focus-visible {
    background-color: $color-dark1;
    box-shadow: inset 4px 0 0 $color-difference;
    color: $color-difference;
  }
  @media (prefers-reduced-motion) {
    transition: none;
  }
}
