::selection {
  background-color: $color-accent;
  color: $color-dark1;
}

p {
  margin-bottom: 2rem;
}

p:last-child {
  margin-bottom: 0;
}

em {
  font-style: italic;
}

sup {
  font-size: 0.8em;
  vertical-align: top;
}
