/* merriweather-300 - latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/merriweather-v25-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/merriweather-v25-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/merriweather-v25-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/merriweather-v25-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/merriweather-v25-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/merriweather-v25-latin-300.svg#Merriweather") format("svg"); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/lato-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/lato-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/lato-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/lato-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/lato-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/lato-v20-latin-regular.svg#Lato")
      format("svg"); /* Legacy iOS */
}
/* lato-italic - latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/lato-v20-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/lato-v20-latin-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/lato-v20-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/lato-v20-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/lato-v20-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/lato-v20-latin-italic.svg#Lato")
      format("svg"); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/lato-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/lato-v20-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/lato-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/lato-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/lato-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/lato-v20-latin-700.svg#Lato")
      format("svg"); /* Legacy iOS */
}
