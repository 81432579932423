html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("merriweather-v25-latin-300.1e2f44f9.eot");
  src: local(""), url("merriweather-v25-latin-300.1e2f44f9.eot#iefix") format("embedded-opentype"), url("merriweather-v25-latin-300.56bdaf9a.woff2") format("woff2"), url("merriweather-v25-latin-300.e5c83369.woff") format("woff"), url("merriweather-v25-latin-300.f9f8d602.ttf") format("truetype"), url("merriweather-v25-latin-300.2a2a1048.svg#Merriweather") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("lato-v20-latin-regular.f4debbbd.eot");
  src: local(""), url("lato-v20-latin-regular.f4debbbd.eot#iefix") format("embedded-opentype"), url("lato-v20-latin-regular.77db3602.woff2") format("woff2"), url("lato-v20-latin-regular.40fe5d42.woff") format("woff"), url("lato-v20-latin-regular.d5bcb4dd.ttf") format("truetype"), url("lato-v20-latin-regular.d22ab101.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("lato-v20-latin-italic.8d35bdf9.eot");
  src: local(""), url("lato-v20-latin-italic.8d35bdf9.eot#iefix") format("embedded-opentype"), url("lato-v20-latin-italic.6edbc86c.woff2") format("woff2"), url("lato-v20-latin-italic.773d6047.woff") format("woff"), url("lato-v20-latin-italic.2f5b2913.ttf") format("truetype"), url("lato-v20-latin-italic.8fcbf222.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("lato-v20-latin-700.7e30041f.eot");
  src: local(""), url("lato-v20-latin-700.7e30041f.eot#iefix") format("embedded-opentype"), url("lato-v20-latin-700.319eebe3.woff2") format("woff2"), url("lato-v20-latin-700.4e061628.woff") format("woff"), url("lato-v20-latin-700.0784fa11.ttf") format("truetype"), url("lato-v20-latin-700.c68fab10.svg#Lato") format("svg");
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  30%, 70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(45deg)scale(0);
  }

  100% {
    transform: rotate(225deg)scale(1);
  }
}

@keyframes caret-fade {
  0%, 10% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  90%, 100% {
    opacity: 0;
  }
}

::selection {
  color: #11090b;
  background-color: #ff0040;
}

p {
  margin-bottom: 2rem;
}

p:last-child {
  margin-bottom: 0;
}

em {
  font-style: italic;
}

sup {
  vertical-align: top;
  font-size: .8em;
}

* {
  box-sizing: border-box;
}

html {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  font-size: 16px;
}

html.is-404 {
  height: 100%;
}

@media (width >= 100em) {
  html {
    font-size: 18px;
  }
}

@media (width >= 130em) {
  html {
    font-size: 20px;
  }
}

body {
  color: #dbbdc4;
  text-wrap: pretty;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #11090b;
  font-family: Lato, helvetica, arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.is-404 body {
  height: 100%;
}

@media (width >= 60em) {
  body {
    line-height: 1.65;
  }
}

.section {
  max-width: 110rem;
  margin: 0 auto 10rem;
}

.section.section--split {
  margin-bottom: 5rem;
}

@media (width >= 80em) {
  .section.section--split {
    align-items: flex-start;
    gap: 4rem;
    margin-bottom: 8rem;
    display: flex;
  }
}

.section:last-child {
  margin-bottom: 0;
}

.section > p {
  max-width: 60rem;
  font-size: 1.25rem;
}

@media (width >= 60em) {
  .section > p {
    font-size: 1.5rem;
  }
}

.section > h2 {
  backdrop-filter: blur(4px);
  color: #fff;
  z-index: 10;
  background-color: #11090be6;
  border-bottom: 4px solid #1d1618;
  padding: .75rem 0;
  font-family: Merriweather, georgia, serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.3;
  position: sticky;
  top: -2px;
  left: 0;
}

@media (width >= 60em) {
  .section > h2 {
    padding: 1rem 0;
    font-size: 3rem;
  }
}

@media (width >= 80em) {
  .section > h2 {
    flex-shrink: 0;
    flex-basis: 20rem;
  }
}

.section > h2:before, .section > h2:after {
  content: "";
  border: 2px solid #0000;
  width: 16px;
  height: 16px;
  margin: -8px 1.5rem 0 0;
  animation: 4s linear infinite fade, 4s linear infinite spin;
  display: none;
  position: absolute;
  top: 50%;
  right: 100%;
}

@media (prefers-reduced-motion) {
  .section > h2:before, .section > h2:after {
    animation: none;
    transform: rotate(45deg);
  }
}

@media (width >= 60em) {
  .section > h2:before, .section > h2:after {
    display: block;
  }
}

.section > h2:before {
  border-color: #ff0040;
  animation-delay: -2000ms;
}

@media (prefers-reduced-motion) {
  .section > h2:before {
    opacity: 0;
  }
}

.section > h2:after {
  border-color: #ff0040;
}

.section > h2.h2--split {
  backdrop-filter: none;
  background-color: #0000;
  border: none;
  margin-bottom: 1.5rem;
  padding: 0;
  font-size: 2.75rem;
  position: static;
}

@media (width >= 60em) {
  .section > h2.h2--split {
    font-size: 3rem;
  }
}

@media (width >= 80em) {
  .section > h2.h2--split {
    flex-shrink: 0;
    flex-basis: 20rem;
    margin-bottom: 0;
  }
}

.header {
  background-color: #1d1618;
  justify-content: center;
  align-items: center;
  height: 30vh;
  min-height: 15rem;
  display: flex;
  position: relative;
}

@media (width >= 60em) {
  .header {
    height: 45vh;
  }
}

.header .angle {
  width: 100%;
  height: 4vw;
  min-height: 30px;
  max-height: 60px;
  position: absolute;
  left: 0;
}

.header .angle--top {
  fill: #ff0040;
  top: -.5px;
}

.header .angle--bot {
  fill: #11090b;
  bottom: -.5px;
}

.header-title {
  align-items: center;
  display: flex;
}

.header-title .icon {
  fill: #ff0040;
  width: 2rem;
  margin-right: .75rem;
  display: block;
}

@media (width >= 22em) {
  .header-title .icon {
    margin-right: 1.25rem;
  }
}

@media (width >= 23em) {
  .header-title .icon {
    width: 3rem;
  }
}

@media (width >= 60em) {
  .header-title .icon {
    width: 4rem;
    margin-right: 2rem;
  }
}

.header-title-text {
  white-space: nowrap;
}

.header-title-text-top {
  color: #fff;
  margin-bottom: 1rem;
  font-family: Merriweather, georgia, serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1;
}

@media (width >= 25em) {
  .header-title-text-top {
    font-size: 2.5rem;
  }
}

@media (width >= 60em) {
  .header-title-text-top {
    margin-bottom: 1.25rem;
    font-size: 3.5rem;
  }
}

.header-title-text-bot {
  color: #fff;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1;
}

@media (width >= 60em) {
  .header-title-text-bot {
    font-size: 1.5rem;
  }
}

.main {
  z-index: 10;
  background-color: #11090b;
  padding: 3rem 2rem 5rem;
  position: relative;
}

@media (width >= 60em) {
  .main {
    padding: 8rem;
  }
}

.project-grid {
  gap: 2rem;
  margin-top: 2rem;
  padding-bottom: 1px;
  display: grid;
}

@media (width >= 40em) {
  .project-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 80em) {
  .project-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cell {
  flex-direction: column;
  display: flex;
}

.cell-picture-wrap {
  background-color: #1d1618;
  margin-bottom: 4px;
  padding: 4px;
  position: relative;
  overflow: hidden;
}

.cell-lqip-wrap {
  pointer-events: none;
  position: absolute;
  inset: 4px;
  overflow: hidden;
}

.cell-lqip {
  filter: blur(6px);
  width: 100%;
  height: 100%;
  transition: transform .6s;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.025);
}

.loaded .cell-lqip {
  transform: scale(1);
}

.cell-picture {
  display: block;
  position: relative;
  overflow: hidden;
}

.cell-image {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

.has-js .cell-image {
  opacity: 0;
  transition: opacity .6s, filter .6s;
}

.has-js .loaded .cell-image {
  opacity: 1;
}

.cell-inner {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.cell-content {
  background-color: #1d1618;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  display: flex;
}

.cell-title {
  color: #fff;
  text-wrap: balance;
  margin-bottom: .5rem;
  font-family: Merriweather, georgia, serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.3;
}

.cell-subtitle {
  color: #fff;
  text-wrap: balance;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  position: relative;
}

.cell-subtitle:after {
  content: "";
  background-color: #3b2b2f;
  width: 25%;
  height: 2px;
  margin-top: 1.25rem;
  display: block;
}

.cell-description {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.cell-location {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-style: italic;
}

.cell-location a {
  color: inherit;
  transition: color .3s;
  position: relative;
}

.cell-location a:hover, .no-js .cell-location a:focus-visible {
  color: #ff0040;
  transition-duration: 0s;
}

.cell-location a:focus-visible {
  color: #fff;
}

@media (prefers-reduced-motion) {
  .cell-location a {
    transition: none;
  }
}

.cell-links {
  margin-top: auto;
}

.cell-link {
  color: #fff;
  background-color: #1d1618;
  margin-top: 4px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
  text-decoration: none;
  transition: background-color .3s, border-color .3s, box-shadow .3s, color .3s;
  display: block;
  box-shadow: inset 4px 0 #3b2b2f;
}

.cell-link:hover, .no-js .cell-link:focus-visible {
  color: #fff;
  background-color: #3b2b2f;
  transition-duration: 0s;
  box-shadow: inset 4px 0 #ff0040;
}

.cell-link:focus-visible {
  color: #fff;
  background-color: #11090b;
  box-shadow: inset 4px 0 #fff;
}

@media (prefers-reduced-motion) {
  .cell-link {
    transition: none;
  }
}

.call-to-action {
  text-align: center;
  background-color: #1d1618;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  padding: 5rem 2rem;
  display: flex;
  position: relative;
}

@media (width >= 60em) {
  .call-to-action {
    min-height: 50vh;
    padding: 7.5vw 10vw;
  }
}

.call-to-action .angle {
  z-index: 5;
  width: 100%;
  height: 4vw;
  min-height: 30px;
  max-height: 60px;
  position: absolute;
  left: 0;
}

.call-to-action .angle--top {
  fill: #11090b;
  top: -.5px;
}

.call-to-action .angle--bot {
  fill: #11090b;
  bottom: -.5px;
}

.call-to-action-title {
  color: #ff0040;
  margin-bottom: 4rem;
  font-family: Merriweather, georgia, serif;
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 1.3;
  position: relative;
}

.call-to-action-title:after {
  content: "";
  background-color: #3b2b2f;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -2rem;
  left: 0;
}

@media (width >= 30em) {
  .call-to-action-title {
    font-size: 2.75rem;
  }
}

.call-to-action-subtitle {
  margin-bottom: 0;
  font-size: 1.25rem;
  position: relative;
}

@media (width >= 30em) {
  .call-to-action-subtitle {
    font-size: 1.5rem;
  }
}

.call-to-action-content {
  font-size: 1.25rem;
}

@media (width >= 30em) {
  .call-to-action-content {
    font-size: 1.5rem;
  }
}

.call-to-action-content a {
  color: #fff;
  font-weight: 700;
  transition: color .3s;
  display: inline-block;
}

.no-js .call-to-action-content a:focus-visible {
  color: #ff0040;
  transition-duration: 0s;
}

.call-to-action-content a:focus-visible {
  color: #fff;
}

@media (hover: hover) {
  .call-to-action-content a:hover {
    color: #ff0040;
    transition-duration: 0s;
  }
}

@media (prefers-reduced-motion) {
  .call-to-action-content a {
    transition: none;
  }
}

.footer {
  background-color: #11090b;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  padding: 5rem 2rem;
  display: flex;
  position: relative;
}

@media (width >= 60em) {
  .footer {
    padding: 6rem 8rem 9rem;
  }
}

.footer .angle {
  z-index: 5;
  width: 100%;
  height: 4vw;
  min-height: 30px;
  max-height: 60px;
  position: absolute;
  left: 0;
}

.footer .angle--bot {
  fill: #ff0040;
  bottom: -.5px;
}

.footer-inner {
  flex-direction: column;
  justify-content: center;
  max-width: 50rem;
  display: flex;
}

.footer-profile {
  flex-basis: 100%;
  justify-content: center;
  margin-bottom: 3rem;
  display: block;
}

@media (width >= 30em) {
  .footer-profile {
    display: flex;
  }
}

.footer-profile-picture-wrap {
  background-color: #1d1618;
  margin: 0 0 2rem;
  padding: 4px;
  position: relative;
  overflow: hidden;
}

@media (width >= 30em) {
  .footer-profile-picture-wrap {
    flex: 0 0 7rem;
    margin: 0 2rem auto 0;
  }
}

.footer-profile-lqip-wrap {
  pointer-events: none;
  position: absolute;
  inset: 4px;
  overflow: hidden;
}

.footer-profile-lqip {
  filter: blur(6px);
  width: 100%;
  height: 100%;
  transition: transform .6s;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.025);
}

.loaded .footer-profile-lqip {
  transform: scale(1);
}

.footer-profile-picture {
  display: block;
  position: relative;
  overflow: hidden;
}

.footer-profile-image {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

.has-js .footer-profile-image {
  opacity: 0;
  transition: opacity .6s;
}

.has-js .loaded .footer-profile-image {
  opacity: 1;
}

.footer-profile-content {
  max-width: 50rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
}

.footer-profile-content a {
  color: #fff;
  white-space: nowrap;
  font-weight: 700;
  transition: color .3s;
  position: relative;
}

.no-js .footer-profile-content a:focus-visible {
  color: #ff0040;
  transition-duration: 0s;
}

.footer-profile-content a:focus-visible {
  color: #fff;
}

@media (hover: hover) {
  .footer-profile-content a:hover {
    color: #ff0040;
    transition-duration: 0s;
  }
}

@media (prefers-reduced-motion) {
  .footer-profile-content a {
    transition: none;
  }
}

.footer-links {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 3rem;
  display: flex;
}

@media (width >= 40em) {
  .footer-links {
    gap: 2rem;
  }
}

.footer-link {
  background-color: #1d1618;
  align-items: center;
  width: calc(50% - 2px);
  padding: .75rem;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

.no-js .footer-link:focus-visible {
  z-index: 1;
  background-color: #3b2b2f;
  transition-duration: 0s;
  position: relative;
}

.footer-link:focus-visible {
  background-color: #11090b;
}

@media (hover: hover) {
  .footer-link:hover {
    z-index: 1;
    background-color: #3b2b2f;
    transition-duration: 0s;
    position: relative;
  }
}

@media (width >= 40em) {
  .footer-link {
    background-color: #0000;
    width: auto;
    padding: .25rem;
  }

  .footer-link:hover, .no-js .footer-link:focus-visible, .footer-link:focus-visible {
    background-color: #0000;
  }
}

.footer-link-icon {
  fill: #fff;
  flex-shrink: 0;
  width: 1.5rem;
  margin: 0 .5rem 0 0;
  transition: fill .3s;
}

.no-js .footer-link:focus-visible .footer-link-icon {
  fill: #ff0040;
  transition-duration: 0s;
}

.footer-link:focus-visible .footer-link-icon {
  fill: #fff;
}

@media (hover: hover) {
  .footer-link:hover .footer-link-icon {
    fill: #ff0040;
    transition-duration: 0s;
  }
}

@media (width >= 40em) {
  .footer-link-icon {
    width: 1.75rem;
    margin: 0 .5rem 0 0;
    display: inline-block;
  }
}

@media (prefers-reduced-motion) {
  .footer-link-icon {
    transition: none;
  }
}

.footer-link-text {
  color: #fff;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  transition: color .3s;
}

.no-js .footer-link:focus-visible .footer-link-text {
  color: #ff0040;
  transition-duration: 0s;
}

.footer-link:focus-visible .footer-link-text {
  color: #fff;
}

@media (hover: hover) {
  .footer-link:hover .footer-link-text {
    color: #ff0040;
    transition-duration: 0s;
  }
}

@media (prefers-reduced-motion) {
  .footer-link-text {
    transition: none;
  }
}

.footer-meta {
  text-align: center;
  flex-basis: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
}

.footer-meta-divider {
  margin: 0 .75rem;
  display: none;
}

@media (width >= 40em) {
  .footer-meta-divider {
    display: inline-block;
  }
}

.footer-meta-copyright {
  margin: 0;
}

@media (width >= 40em) {
  .footer-meta-copyright {
    display: inline-block;
  }
}

.footer-meta-resume {
  margin-top: 1.25rem;
  margin-bottom: 0;
}

@media (width >= 40em) {
  .footer-meta-resume {
    margin-top: 0;
    display: inline-block;
  }
}

.footer-meta-resume-link {
  color: #fff;
  white-space: nowrap;
  font-weight: 700;
  transition: color .3s;
  display: inline-block;
  position: relative;
}

.no-js .footer-meta-resume-link:focus-visible {
  color: #ff0040;
  transition-duration: 0s;
}

.footer-meta-resume-link:focus-visible {
  color: #fff;
}

@media (hover: hover) {
  .footer-meta-resume-link:hover {
    color: #ff0040;
    transition-duration: 0s;
  }
}

@media (prefers-reduced-motion) {
  .footer-meta-resume-link {
    transition: none;
  }
}

.footer-meta-code {
  margin-top: 1.25rem;
  margin-bottom: 0;
}

@media (width >= 40em) {
  .footer-meta-code {
    margin-top: 0;
    display: inline-block;
  }
}

.footer-meta-code-link {
  color: #11090b;
  background-color: #fff;
  margin: 0 .1rem;
  padding: .25rem .35rem;
  font-family: consolas, courier, monospace;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: inline-block;
  position: relative;
}

.no-js .footer-meta-code-link:focus-visible {
  color: #11090b;
  background-color: #ff0040;
  transition-duration: 0s;
}

.footer-meta-code-link:focus-visible {
  background-color: #fff;
}

@media (hover: hover) {
  .footer-meta-code-link:hover {
    color: #11090b;
    background-color: #ff0040;
    transition-duration: 0s;
  }
}

@media (prefers-reduced-motion) {
  .footer-meta-code-link {
    transition: none;
  }
}

.footer-meta-code-link span {
  background: currentColor;
  width: 2px;
  margin-left: 2px;
  animation: 1s infinite caret-fade;
  display: inline-block;
}

@media (prefers-reduced-motion) {
  .footer-meta-code-link span {
    display: none;
  }
}

.page-not-found {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.page-not-found .angle {
  width: 100%;
  height: 4vw;
  min-height: 30px;
  max-height: 60px;
  position: absolute;
  left: 0;
}

.page-not-found .angle--top {
  fill: #ff0040;
  top: -.5px;
}

.page-not-found .angle--bot {
  fill: #ff0040;
  bottom: -.5px;
}

.page-not-found-title {
  align-items: center;
  display: flex;
}

.page-not-found-title-text {
  color: #fff;
  margin-bottom: 0;
  font-family: Merriweather, georgia, serif;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.focus-indicator {
  mix-blend-mode: difference;
  opacity: 0;
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 100;
  background-color: #fff;
  width: 1px;
  height: 1px;
  transition: opacity .3s, transform .3s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.focus-indicator.no-js {
  display: none;
}

@media (prefers-reduced-motion) {
  .focus-indicator {
    transition: none;
  }
}

html:has(:focus-visible) .focus-indicator {
  opacity: 1;
}

a:focus {
  outline: none;
}

a:focus-visible {
  outline-offset: 4px;
  outline: 4px solid #fff;
}

.has-js a:focus, .has-js a:focus-visible {
  outline: none;
}
/*# sourceMappingURL=index.792125bf.css.map */
