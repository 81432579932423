.page-not-found {
  align-items: center;
  display: flex;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  position: relative;
  text-align: center;
}

.page-not-found .angle {
  height: 4vw;
  left: 0;
  min-height: 30px;
  max-height: 60px;
  position: absolute;
  width: 100%;
}

.page-not-found .angle--top {
  fill: $color-accent;
  top: -0.5px;
}

.page-not-found .angle--bot {
  bottom: -0.5px;
  fill: $color-accent;
}

.page-not-found-title {
  align-items: center;
  display: flex;
}

.page-not-found-title-text {
  color: #fff;
  font-family: $font-serif;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 0;
}
