.header {
  align-items: center;
  background-color: $color-dark2;
  display: flex;
  height: 30vh;
  justify-content: center;
  min-height: 15rem;
  position: relative;
  @media (min-width: 60em) {
    height: 45vh;
  }
}

.header .angle {
  height: 4vw;
  left: 0;
  max-height: 60px;
  min-height: 30px;
  position: absolute;
  width: 100%;
}

.header .angle--top {
  fill: $color-accent;
  top: -0.5px;
}

.header .angle--bot {
  bottom: -0.5px;
  fill: $color-dark1;
}

.header-title {
  align-items: center;
  display: flex;
}

.header-title .icon {
  display: block;
  fill: $color-accent;
  margin-right: 0.75rem;
  width: 2rem;
  @media (min-width: 22em) {
    margin-right: 1.25rem;
  }
  @media (min-width: 23em) {
    width: 3rem;
  }
  @media (min-width: 60em) {
    margin-right: 2rem;
    width: 4rem;
  }
}

.header-title-text {
  white-space: nowrap;
}

.header-title-text-top {
  color: #fff;
  font-family: $font-serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 1rem;
  @media (min-width: 25em) {
    font-size: 2.5rem;
  }
  @media (min-width: 60em) {
    font-size: 3.5rem;
    margin-bottom: 1.25rem;
  }
}

.header-title-text-bot {
  color: #fff;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 0;
  @media (min-width: 60em) {
    font-size: 1.5rem;
  }
}
