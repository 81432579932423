* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
  font-size: 16px;
  &.is-404 {
    height: 100%;
  }
  @media (min-width: 100em) {
    font-size: 18px;
  }
  @media (min-width: 130em) {
    font-size: 20px;
  }
}

body {
  background-color: $color-dark1;
  color: $color-light1;
  font-family: $font-sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-wrap: pretty;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  .is-404 & {
    height: 100%;
  }
  @media (min-width: 60em) {
    line-height: 1.65;
  }
}

.section {
  margin: 0 auto 10rem;
  max-width: 110rem;
  &.section--split {
    margin-bottom: 5rem;
  }
  @media (min-width: 80em) {
    &.section--split {
      align-items: flex-start;
      display: flex;
      gap: 4rem;
      margin-bottom: 8rem;
    }
  }
}

.section:last-child {
  margin-bottom: 0;
}

.section > p {
  font-size: 1.25rem;
  max-width: 60rem;
  @media (min-width: 60em) {
    font-size: 1.5rem;
  }
}

.section > h2 {
  background-color: rgba($color-dark1, 0.9);
  backdrop-filter: blur(4px);
  border-bottom: 4px solid $color-dark2;
  color: #fff;
  font-family: $font-serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.3;
  padding: 0.75rem 0;
  position: sticky;
  left: 0;
  top: -2px; // prevent blurred subpixels from peeking through on top
  z-index: 10;
  @media (min-width: 60em) {
    font-size: 3rem;
    padding: 1rem 0;
  }
  @media (min-width: 80em) {
    flex-basis: 20rem;
    flex-shrink: 0;
  }
}

.section > h2:before,
.section > h2:after {
  animation: fade 4000ms linear infinite, spin 4000ms linear infinite;
  border: 2px solid transparent;
  content: "";
  display: none;
  height: 16px;
  margin: -8px 1.5rem 0 0;
  position: absolute;
  right: 100%;
  top: 50%;
  width: 16px;
  @media (prefers-reduced-motion) {
    animation: none;
    transform: rotate(45deg);
  }
  @media (min-width: 60em) {
    display: block;
  }
}

.section > h2:before {
  animation-delay: -2000ms;
  border-color: $color-accent;
  @media (prefers-reduced-motion) {
    opacity: 0;
  }
}

.section > h2:after {
  border-color: $color-accent;
}

.section > h2.h2--split {
  background-color: transparent;
  backdrop-filter: none;
  border: none;
  font-size: 2.75rem;
  margin-bottom: 1.5rem;
  padding: 0;
  position: static;
  @media (min-width: 60em) {
    font-size: 3rem;
  }
  @media (min-width: 80em) {
    flex-basis: 20rem;
    flex-shrink: 0;
    margin-bottom: 0;
  }
}
